
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import myCarouselData from '../bodyData/myCarousal';

const MyCarousel = () => {

  return (

    <section className="logos-slider-section">
      <div className="container">
        <h2 className="customer-logos-h2 text-center font-weight-bold">Our Partners</h2>
        <section className="customer-logos slider">


          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            autoplay={{
              delay: 1200,
              disableOnInteraction: true,
            }}
            // pagination={{
            //   clickable: true,
            // }}
            // navigation={true}
            breakpoints={{
              450: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {myCarouselData.map((data, index) => (
              <SwiperSlide key={index}>
                {/* <div  className="slide"><h2 >{data.client}</h2></div> */}

                <div className=" slide">
                  <img className='clients-logos-carousal' src={data.img} alt={data.alt} />
                </div>


              </SwiperSlide>

            ))}
            {/* <SwiperSlide> <div className="slide"><h2>KARACHI HOT AND SPICY</h2></div></SwiperSlide>
                <SwiperSlide> <div className="slide"><h2>LASANIA</h2></div></SwiperSlide>
                <SwiperSlide><div className="slide"><h2>EMAAN DAKA</h2></div></SwiperSlide>
                <SwiperSlide><div className="slide"><h2>OPUS ESTATE</h2></div></SwiperSlide>
                <SwiperSlide><div className="slide"><h2>THE FAMILLE DENTIST</h2></div></SwiperSlide>
                <SwiperSlide><div className="slide"><h2>ACCOUNTING WITH MUSTAEEN </h2></div></SwiperSlide> */}

          </Swiper>

        </section>
      </div>
    </section>


  );
};

export default MyCarousel;



