import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CallOn from './CallOn.jsx'


function ContactSheet() {
  const [isOpen, setIsOpen] = useState(false);

  const openSheet = () => {
    setIsOpen(true);
  };

  const closeSheet = () => {
    setIsOpen(false);
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: 'Fast Form Main',
    message: ''
  });

  const { sheetStyles, contentStyles, fromHeaderStyle, buttonStyle, fromStyle, inputGroup } = stylesfunc(isOpen);





  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };




  const sendEmail = () => {
    const toastId = toast.loading("We're Delivering Your Precious Response");
    console.log(toastId)

    if (validateForm()) {

      fetch('https://node-email-server-master.vercel.app/sendmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);


          if (data.status === '1') {
            console.log("success" + toastId)
            toast.dismiss(toastId)
            showSuccessToast()
          } else {
            console.log(toastId)
            showFailToast('Network Error')
          }
        })
        .catch(error => {
          console.error('Error:', error);
          showFailToast("catch" + error)
        });


    }
    else {
      console.log("else" + toastId)
      toast.dismiss(toastId)
      showFailToast("Mail couldn't be sent")
      return
    }

  };

  const validateForm = () => {

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      formData.name.trim() === '' ||
      formData.email.trim() === '' ||
      formData.phone.trim() === '' ||
      formData.message.trim() === ''
    ) {


      showFailToast('All fields are required')
      return false;
    }
    // else if (!emailRegex.test(formData.email.trim())) {
    //   setError('Invalid email address');
    //   return false;
    // } else if (formData.phone.trim().length !== 10 || !/^\d+$/.test(formData.phone.trim())) {
    //   setError('Invalid phone number');
    //   return false;
    // }


    return true;
  };

  const showSuccessToast = () => {
    toast.success("Your message has been sent. Thank you!");
  };
  const showFailToast = (data) => {
    toast.error(data);
  };


  return (
    <div>
      <button onClick={openSheet} className="contactButton">
        Contact
      </button>

      {isOpen && (
        <div
          style={sheetStyles}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closeSheet();
            }
          }}
        >
          <div className="sheet" style={contentStyles}>
            <div>
              <div style={fromHeaderStyle}>
                <h2>Fast Contact</h2>
                <button className="sheetCloseButton" style={buttonStyle} onClick={closeSheet}>
                  X
                </button>
              </div>
              <CallOn />


              <form id="contact-form" style={fromStyle}>
                <div className="input-group" style={inputGroup}>
                  <input type="text" id="name" onChange={handleChange} value={formData.name} placeholder="NAME" />
                </div>
                <div className="input-group" style={inputGroup}>
                  <input type="email" id="email" onChange={handleChange} value={formData.email} placeholder="EMAIL" />
                </div>
                <div className="input-group" style={inputGroup}>
                  <input type="number" id="phone" onChange={handleChange} value={formData.phone} placeholder="PHONE NUMBER" />
                </div>


                <div className="input-group" style={inputGroup}>
                  <textarea id="message" className="txtStyle" rows="7" placeholder="YOUR MESSAGE INCLUDING" onChange={handleChange} value={formData.message}></textarea>
                </div>
                <button id="submit" type="button" onClick={sendEmail} >Submit</button>
              </form>

            </div>
          </div>
          <ToastContainer position="bottom-right" autoClose={5000} />
        </div>
      )}
    </div>
  );
}

export default ContactSheet;



function stylesfunc(isOpen) {
  const sheetStyles = {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 50,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    transition: "opacity 0.5s",
    opacity: isOpen ? 1 : 0,
    display: isOpen ? "block" : "none",
  };

  const contentStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "auto",
    // backgroundColor: "#FFFFFF",
    padding: "20px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    transition: "transform 0.5s",
    transformOrigin: "center center",
    opacity: isOpen ? 1 : 0,
  };


  const buttonStyle = {
    padding: "7px 10px",
    border: "none",
    borderRadius: "50px",
    cursor: "pointer",
  };

  const fromHeaderStyle = {
    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%',
    alignItems: 'center'
  };

  const fromStyle = {
    width: '100%',
    paddingTop: '20px'
  };

  const inputGroup = {
    marginBottom: '20px',
  };
  // const inputGroupTextArea={
  //   border: 'none'
  // }
  return { sheetStyles, contentStyles, fromHeaderStyle, buttonStyle, fromStyle, inputGroup };
}


