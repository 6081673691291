
import React, { useState, useEffect } from 'react';
import testimonialsMultiple from './bodyData/data';

const TestimonialClients = () => {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTestimonialIndex((prevIndex) => (prevIndex + 1) % testimonialsMultiple.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const currentTestimonial = testimonialsMultiple[currentTestimonialIndex];
  const nextTestimonialIndex = (currentTestimonialIndex + 1) % testimonialsMultiple.length;
  const nextTestimonial = testimonialsMultiple[nextTestimonialIndex];

  return (
    <div>
      <div className="testimonial-banner">
        <div className="testimonial-grey">
          <div className="heading width90">
            <div className="testimonial-heading">
              <h2>A Word From Our Clients</h2>
            </div>
          </div>

          <div className="testimonial-slider width90">
            <div className="testimonial">
              <img className="quote" src="img/images2/comma.png" alt="" />
              <p>{currentTestimonial.quote}</p>
              <p className="author">{currentTestimonial.author}</p>
              <p className="designation">{currentTestimonial.designation}</p>
            </div>

            <div className="testimonial">
              <img className="quote" src="img/images2/comma.png" alt="" />
              <p>{nextTestimonial.quote}</p>
              <p className="author">{nextTestimonial.author}</p>
              <p className="designation">{nextTestimonial.designation}</p>
            </div>
          </div>

          <div className="hyper-link margin7perc">
            <div className="SpecialLink IWantAMouseOVerNowBut75">
              <a href="index.html" title="Write Your Testimonial">
                Write Your Testimonial
              </a>
              <div className="Line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialClients;



// // TestimonalClients.js

// import React, { Component } from 'react';
// import testimonialsMultiple from './bodyData/data';

// class TestimonialClients extends Component  {


//   constructor(props) {
//     super(props);
//     this.state = {
//       currentTestimonialIndex: 0,
      

//     };
//   }

//   componentDidMount() {
//     this.displayTestimonials();
//   }

//   displayTestimonials = () => {
//     setTimeout(() => {
//       this.setState((prevState) => ({
//         currentTestimonialIndex: (prevState.currentTestimonialIndex + 1) % testimonialsMultiple.length,
          
//       }));
//       this.displayTestimonials();
//     }, 5000);
//   };


//   render() {
//     const currentTestimonial = testimonialsMultiple[this.state.currentTestimonialIndex];
//     const currentTestimonial2 = testimonialsMultiple[this.state.currentTestimonialIndex +1 ];

//   return (
//     <div>
//       <div className="testimonial-banner">
//         <div className="testimonial-grey">
//           <div className="heading width90">
//             <div className="testimonial-heading">
//               <h2>A Word From Our Clients</h2>
//             </div>
//           </div>

//           <div className="testimonial-slider width90">
         
//             <div className="testimonial" >
//                 <img className="quote" src="img/images2/comma.png" alt="" />
//                 <p>{currentTestimonial.quote}</p>
//                 <p className="author">{currentTestimonial.author}</p>
//                 <p className="designation">{currentTestimonial.designation}</p>
//             </div>
          
//           <div className="testimonial">
//                 <img className="quote" src="img/images2/comma.png" alt="" />
//                 <p>{currentTestimonial2.quote}</p>
//                 <p className="author">{currentTestimonial2.author}</p>
//                 <p className="designation">{currentTestimonial2.designation}</p>
//             </div>

//           </div>

//           <div className="hyper-link margin7perc">
//             <div className="SpecialLink IWantAMouseOVerNowBut75">
//               <a href="index.html" title="Write Your Testimonial">
//                 Write Your Testimonial
//               </a>
//               <div className="Line"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
//   }     
// }

// export default TestimonialClients;