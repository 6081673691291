import React from 'react'

const OurVision = () => {
  return (
    <div>
        <section className="img-bg">
  <div className="tbl">
    <div className="tbl-col">


      <div className="right50">
        <div className="services">
          <h2>Our Vision</h2>
        </div>

        <div className="title-us extra">
          <div className="line"></div>
          <h3 className="who-we-are" style={{alignItems: "end"}}> What we do</h3>
        </div>
        <div className="description-who-we-are extra margin7perc">
          <p>

            Smart planning commences by defining the correct objective. We collaborate closely with our clients throughout the entire journey of crafting marketing strategies, designing and developing, starting from the conceptual phase, and proceeding through testing, delivery, and ongoing support to fulfill their expectations. Our initiatives initiate with a conversation, where our seasoned and skilled team can genuinely grasp our clients' requirements and aspirations.

          </p>
        </div>

        <div className="hyper-link">
          <div className="SpecialLink IWantAMouseOVerNowBut75">
            <a href="index.html" title="View Our Services">
              View Our Services
            </a>
            <div className="Line"></div>
          </div>
        </div>
        </div>


      <div className="right50">
        <div className="services">
          <h2>Approach</h2>
        </div>

        <div className="title-us extra">
          <div className="line"></div>
          <h3 className="who-we-are"> What we do</h3>
        </div>
        <div className="description-who-we-are extra margin7perc">
          <p>
            Our approach revolves around finding a viable and beneficial solution tailored to your business. We are enthusiastic about imparting our expertise to our clients, working in partnership with various companies across diverse sectors to formulate strategies that yield meaningful outcomes. We aim to provide our clients with the appropriate resources that complement their technology effectively.
          </p>
        </div>

        <div className="hyper-link">
          <div className="SpecialLink IWantAMouseOVerNowBut75">
            <a href="index.html" title="View Our Services">
              View Our Services
            </a>
            <div className="Line"></div>
          </div>
        </div>


      </div>






    </div>
  </div>
</section>
      
    </div>
  )
}

export default OurVision
