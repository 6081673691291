import { Link, useMatch, useResolvedPath } from "react-router-dom";
import DarkButton from "./Darkbutton";
import { useEffect, useState } from "react";
import MenuBar from "./MenuBar";
import ContactSheet from "../pagesContents/contactPage/ContactSheet";
// import ContactHover from "../contactPage/ContactHover";


const Nav = ({ isItDarkMode }) => {

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992);

    // const [isContactFormVisible, setContactFormVisible] = useState(false);


    useEffect(() => {
        const handleScreenWidth = () => {
            setIsSmallScreen(window.innerWidth < 992)
        }

        window.addEventListener('resize', handleScreenWidth)

        return () => {
            window.removeEventListener('resize', handleScreenWidth);
        }
    });

    // const toggleContactForm = () => {
    //     setContactFormVisible(!isContactFormVisible);
    //   };

    useState()

    return (
        <div>

            <header style={{ position: 'fixed' }}>

                {isSmallScreen && <DarkButton isItDarkMode={isItDarkMode} />}
                <Link className='logo' to='/' >
                    <img src="img/images2/adxLogo2.png" alt="logo" />
                </Link>
                {/* <img className="logo nonTouch"   src="img/images2/adxLogo2.png" alt="logo" /> */}
                {/* <Link to='/' >
                
                </Link> */}

                <nav >
                    <div id="wildcard" >
                        <ul className="nav__links">
                            <ToLink to='/' text='Home' />
                            <ToLink to='/about' text='About Us' />
                            <ToLink to='/services' text='Services' />
                            {/* <ToLink to='/coreteam' text='Core Team' />// not needed by client anymore */}
                            <ToLink to='/contact' text='Contact' />

                        </ul>
                    </div>
                </nav>
                {isSmallScreen && <MenuBar />}

                <ContactSheet />
                {/* <button className="contactButton" onClick={toggleContactForm}>Contact</button>
                <ContactHover isContactFormVisible={isContactFormVisible}/> */}
                {!isSmallScreen && <DarkButton isItDarkMode={isItDarkMode} />}


            </header>

        </div>
    )
}

export default Nav

function ToLink({ to, text }) {

    const resolvedPath = useResolvedPath(to);
    //   const matched= 
    useMatch({ path: resolvedPath.pathname, end: true })

    //    console.log(resolvedPath.pathname)

    return (<li><Link to={to}>{text}</Link> </li>)
}