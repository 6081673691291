

const myCarouselData = [
    {
        img: "./img/client/accountingwithmustashan.png",
        alt: "ACCOUNTING WITH MUSTAEEN"
    }, 
    {
        img: "./img/client/eatolog.png",
        alt: "EATOLOGY"
    }, 
    {
        img: "./img/client/eman-dhaka-rm.png",
        alt: "EMAAN DAKA"
    }, 
    {
        img: "./img/client/hotnspicy.png",
        alt: "KARACHI HOT AND SPICY"
    }, 
    {
        img: "./img/client/lasania-rm.png",
        alt: "LASANIA"
    }, 
    {
        img: "./img/client/meltingspotpizza.png",
        alt: "MELTING SPOT PIZZA"
    }, 
    {
        img: "./img/client/newyorkers.png",
        alt: "ACCOUNTING WITH MUSTAEEN"
    }, 
    {
        img: "./img/client/opusestate.png",
        alt: "OPUS ESTATE"
    }, 
    {
        img: "./img/client/smartinvest.png",
        alt: "SMART INVEST FINANCIAL GROUP"
    }, 
    {
        img: "./img/client/thefamiliedentist.png",
        alt: "THE FAMILLE DENTIST"
    }, 

]

export default myCarouselData