import React, { useEffect, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

const MenuBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsMenuOpen(false);
            }
        };

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <div
                id="menu-bar"
                className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}
                onClick={() => setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)}
            ></div>

            {windowWidth <= 992 && (
                <nav className={isMenuOpen ? 'active' : ''}>
                    <div id="wildcard">
                        <div className='menubar992'>
                            <div
                                id="menu-bar992"
                                className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}
                                onClick={() => setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)}
                            ></div>
                        </div>
                        <ul className="nav__links_wildcard">
                            <div onClick={() => setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)}><ToLink to="/" text="Home" /></div>
                            <div onClick={() => setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)}><ToLink to="/about" text="About Us" /></div>
                            <div onClick={() => setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)}><ToLink to="/services" text="Services" /></div>
                            {/* <div onClick={() => setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)}><ToLink to="/coreteam" text="Core Team" /></div> */}
                            <div onClick={() => setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)}><ToLink to="/contact" text="Contact" /></div>
                        </ul>
                    </div>
                </nav>
            )}
        </div>
    );
};

export default MenuBar;

function ToLink({ to, text }) {
    const resolvedPath = useResolvedPath(to);
    //   const matched=
    useMatch({ path: resolvedPath.pathname, end: true });

    // console.log(resolvedPath.pathname);

    return <li><Link to={to}>{text}</Link></li>;
}
