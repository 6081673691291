
import React from 'react'

import TextTemplate from '../pagesContents/homePage/components/TextTemplate.js';
import ServicesComponent  from '../pagesContents/homePage/components/ServicesComponent.js';
import MyCarousel from '../pagesContents/homePage/components/MyCarousel.jsx';
import Testimonials from '../pagesContents/homePage/components/testimonials/Testimonials.js';
import AnimateBox from '../pagesContents/homePage/animatedSection/AnimateBox.js';

const Home = () => {
  return (
    <>
    <TextTemplate/>
    <AnimateBox/>
    <ServicesComponent/>
    <MyCarousel/> 
    <Testimonials/>
    </>
  )
}

export default Home
