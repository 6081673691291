import React from 'react';
import TestimonialChange from './TestimonailChange.js';
import TestimonialLogos from './logos/TestimonialLogos.jsx'
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const Testimonials = () => {
  return (

    <section>
      <div className="testimonial-banner">
        <div className="testimonial-grey">
          <div className="display-row floatL">
            <div className="display-cell floatL width100">
              <div className="display-last floatL width100">
                <div className="testimonials-written">
                  <div className="testimonial-heading">
                    <h2>Clients Across The Globe</h2>
                  </div>
                  <img src="img/images2/comma.png" alt="" />
                  <div className="testimonial-slider">
                    <TestimonialChange />
                  </div>
                </div>
                <div className="testimonials-logos">
                  <TestimonialLogos />
                </div>
              </div>
              <div className="hyper-link margin7perc ">
                <div className="SpecialLink IWantAMouseOVerNowBut75">

                  <ToLink to='/contact' text='Book Your Appointment' />

                  <div className="Line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  )
}

export default Testimonials



function ToLink({ to, text }) {

  const resolvedPath = useResolvedPath(to);
  useMatch({ path: resolvedPath.pathname, end: true })
  return (<Link to={to}>{text}</Link> )
}