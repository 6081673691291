import React from 'react'

import WhoWeAre from '../pagesContents/aboutPage/WhoWeAre.js'
import WhatWeDo from '../pagesContents/aboutPage/WhatWeDo.js'
import MoreAboutUs from '../pagesContents/aboutPage/MoreAboutUs.js'
import OurVision from '../pagesContents/aboutPage/OurVision.js'
import TestimonalClients from '../pagesContents/aboutPage/TestimonalClients.js'
import RecentWork from '../pagesContents/aboutPage/RecentWork.js'

const About = () => {
  return (
    <div>
      <WhoWeAre/>
      <MoreAboutUs/>
      <WhatWeDo/>
      <OurVision/>
      <TestimonalClients/>
      <RecentWork/>
    </div>
  )
}

export default About
