import React from 'react'

function PageNotFound() {
  return (
    <>
      <h1 style={{"minHeight": "60vh",
       "display": "flex",
       "justifyContent": "center",
       "alignItems": "center"
       }}>Page Not Found Woosh!</h1>
    </>
  )
}

export default PageNotFound
