import React from 'react'

const CallOn = () => {
  return (
    <>
       <a className="call-on-phone-number" href='tel:+923290239239'>
               <p>Call us on : +92 329 0239 239</p>
              </a>
    </>
  )
}

export default CallOn
