
// moreAboutUsData.js

export const moreAboutUsData = {
  title: "NEW AGE OF SYSTEMS BY AD X",
  description1: "Before we start working together, we want you to stop thinking about us as just another temporary marketing agency. We are a team extension for you.",
  description2: "At AdX. Factor, we are a team of supercharged, inventive thinkers equipped to conceptualize creative campaigns for small and large enterprises. Our aim is to take the burdens off your plates and offer top-of-mind strategy, design, and digital marketing solutions, adding the “X factor” to your brand story. Our marketing agency is dedicated to providing creative designs with a strong emphasis on efficient time management. We understand the importance of delivering work on time and strive to meet all deadlines without any delays.",
  description3: "From guerrilla marketing tactics to unexpected twists in storytelling, we infuse that impactful 'X Factor' that transforms your brand from normal to extraordinary.",
};

// testimonialData.js

const testimonialsMultiple = [
  {
    quote: "I highly recommend Adx Factor for digital marketing, social media management, and branding. They have been instrumental in helping me grow my business from the ground up. With their help, my business has been able to reach new heights and my sales have been increasing daily thanks to their marketing and management plans. Adx Factor is a reliable and professional partner that I would recommend to anyone looking to take their business to the next level.",
    author: "Alex Johnson",
    designation: "CEO at ClearTrim",
  },
  {
    quote: "I highly recommend Adx Factor, a group of experts, to anyone who wants to make their business visible on the internet. With their help, I started this journey, and their excellent design work has been really impressive. As a teacher, I didn't know much about online business, but Adx Factor gave me the expert advice and constant support I needed. They are really good at digital marketing, managing social media, and branding. They not only met my expectations but exceeded them. Choosing Adx Factor has been a decision I'm very thankful for.",
    author: "Sophia Martinez",
    designation: "Managing Partner at OLabs",
  },
  {
    quote: "I had the pleasure of working with Adx Factor for my digital marketing, social media management and branding needs. I was amazed by the results that I saw in a short period of time. My food sales increased 10 times due to their social media marketing strategies. They have a great team of experts who are knowledgeable and creative in their approach. They have been able to create unique designs that have been able to capture the attention of my target audience. I would highly recommend Adx Factor for any of your digital marketing, social media management, and branding needs.",
    author: "Emily Davis",
    designation: "CEO at ClearTrim",
  },
  {
    quote: "AdX Factor has been instrumental in helping our business grow. Their digital marketing strategies and social media management have significantly increased our online presence and sales. They are reliable and professional, always delivering on time.",
    author: "Sarah Brown",
    designation: "Marketing Director at GreenLeaf",
  },
  {
    quote: "Working with AdX Factor has been a game-changer for our company. Their expertise in digital marketing and branding has taken our business to new heights. Their creative approach and dedication are unmatched.",
    author: "Michael Richards",
    designation: "Founder & CEO at TechHive",
  },
  {
    quote: "As a small business owner, I struggled with online visibility. AdX Factor's team provided me with expert advice and constant support. Their social media strategies have boosted my sales tremendously. I highly recommend them.",
    author: "Laura Garcia",
    designation: "Owner at Sweet Treats Bakery",
  },
  {
    quote: "AdX Factor's innovative campaigns and creative designs have made a huge difference for our brand. They understand our vision and consistently deliver results that exceed our expectations.",
    author: "David Thompson",
    designation: "COO at UrbanStreets Apparel",
  },
  {
    quote: "Their professionalism and efficiency are top-notch. AdX Factor developed our new website and enhanced our online presence through social media and Google Ads. We've seen a significant improvement in our engagement and sales.",
    author: "Emily Watson",
    designation: "Head of Marketing at PureHealth",
  },
];

export default testimonialsMultiple;
