import React, { useEffect, useState } from 'react';
import {  IoIosMoon,  IoMdSunny } from 'react-icons/io';


const DarkButton = ({isItDarkMode}) => {
    const [isDarkMode, setIsDarkMode] = useState(isItDarkMode);
    

    useEffect(() => {

        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setIsDarkMode(prefersDarkMode);
        initializeMode(prefersDarkMode);
    }, []);

    const toggleDarkMode = () => {
        setIsDarkMode((prevMode) => !prevMode);
    };
    

   
    const  initializeMode = (isDarkMode) => {
        const body = document.body;


        if (isDarkMode) {
            body.classList.add('dark-mode');
        } else {
            body.classList.remove('dark-mode');
        }
    };

    useEffect(() => {

        const body = document.body;


        if (isDarkMode) {
            body.classList.add('dark-mode');

        } else {
            body.classList.remove('dark-mode');
        }
    }, [isDarkMode]);


    // console.log('isDarkMode:', isDarkMode);
    // console.log('isItDarkMode:', isItDarkMode);

    return (
        <div>

            <button id="toggleDarkModeButton" onClick={toggleDarkMode}>
                <div id="modeIcon"> {(isDarkMode)? <IoIosMoon className='moon-icon' /> : <IoMdSunny className='sun-icon' />}</div>
            </button>

        </div>
    );
};

export default DarkButton;
