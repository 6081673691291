






const logoData = [
  {
    src: "./img/client/accountingwithmustashan.png",
    alt: "ACCOUNTING WITH MUSTAEEN",
    title: "ACCOUNTING WITH MUSTAEEN",
  },
  {
    src: "./img/client/eatolog.png",
    alt: "EATOLOGY",
    title: "EATOLOGY",
  },
  {
    src: "./img/client/eman-dhaka-rm.png",
    alt: "EMAAN DAKA",
    title: "EMAAN DAKA",
  },
  {
    src: "./img/client/hotnspicy.png",
    alt: "KARACHI HOT AND SPICY",
    title: "KARACHI HOT AND SPICY",
  },
  {
    src: "./img/client/lasania-rm.png",
    alt: "LASANIA",
    title: "LASANIA",
  },
  {
    src: "./img/client/meltingspotpizza.png",
    alt: "MELTING SPOT PIZZA",
    title: "MELTING SPOT PIZZA",
  },
  {
    src: "./img/client/newyorkers.png",
    alt: "ACCOUNTING WITH MUSTAEEN",
    title: "ACCOUNTING WITH MUSTAEEN",
  },
  {
    src: "./img/client/opusestate.png",
    alt: "OPUS ESTATE",
    title: "OPUS ESTATE",
  },
  {
    src: "./img/client/smartinvest.png",
    alt: "SMART INVEST FINANCIAL GROUP",
    title: "SMART INVEST FINANCIAL GROUP",
  },
  {
    src: "./img/client/thefamiliedentist.png",
    alt: "THE FAMILLE DENTIST",
    title: "THE FAMILLE DENTIST",
  },

];

export default logoData;