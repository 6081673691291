import React from 'react'
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const Footer = () => {
  return (
    <div>

      <footer className="sticky-footer">
        <div className="rowFooter">
          <div className="colFooter">
            <img src="img/images2/adxLogo2.png" className="logofooter" alt="" />
            {/* <!--      <h3>DROP US A LINE</h3>--> */}
            <p className="footer-paragraph">DROP US A LINE<br />Looking Forward to Hearing From You </p>

          </div>
          <div className="colFooter">
            <h3>Office
              <div className="underline-h3"><span></span></div>
            </h3>
            <p>DHA Phase 2</p>
            <p>Lahore, Punjab</p>
            <p>Pakistan 54000</p>
            <a href='mailTo:Hello.adxfactor@gmail.com' ><p className="email-id">Hello.adxfactor@gmail.com</p></a>
            <a href='mailto:hello@adxfactor.com' ><p className="email-id">hello@adxfactor.com</p></a>
            <a href="tel:+923290239239"><h4> +92 3290239239 </h4></a>

          </div>
          <div className="colFooter">
            <h3>Links
              <div className="underline-h3"><span></span></div>
            </h3>
            <ul className="footer-ul">
              {/* <li><a href="index.html" > </a>Home</li>
        <li><a href="adxFactor-digital-marketing-advertising-company.html"> </a>About Us</li>
        <li><a href="404.html"> </a>Services</li>
        <li><a href="our-core-team-for-you.html"> </a>Core Team</li>
        <li><a href="contact-us-today.html"> </a>Contact Us</li> */}
              {/* ask if name shoudld be  dxFactor-digital-marketing-advertising-company or simple about us */}


              <ToLink to='/' text='Home' />
              <ToLink to='/about' text='About Us' />
              <ToLink to='/services' text='Services' />
              {/* <ToLink to='/coreteam' text='Core Team' /> */}
              <ToLink to='/contact' text='Contact' />
            </ul>
          </div>
          <div className="colFooter" >
            <h3>Newsletter
              <div className="underline-h3"><span></span></div>
            </h3>
            <form className="formFooter" onSubmit={(e) => { e.preventDefault() }}>
              <i className="fa-regular fa-envelope"></i>
              <input type="email" placeholder="Your Email Here " required />
              <button className="buttonFooter" type="submit">  <i className="fa-solid fa-arrow-right"></i></button>
            </form>
            <div className="social-icon-footer">


              <a href='https://web.facebook.com/profile.php?id=61550773778679' target='_blank' rel="noreferrer">
                <i className="fa-brands fa-facebook"></i>
              </a>


              <a href='https://www.instagram.com/adxfactor/' target='_blank' rel="noreferrer">
                <i className="fa-brands fa-instagram"> </i>
              </a>


              <a href='https://www.linkedin.com/company/adx-factor/' target='_blank' rel="noreferrer">
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
              {/* <i className="fa-brands fa-whatsapp"></i> */}

            </div>
          </div>

        </div>
        <hr />
        <p className="copy-right">AdxFactor © 2023 - All Rights Reserved </p>
      </footer>

    </div>
  )
}

export default Footer



function ToLink({ to, text }) {

  const resolvedPath = useResolvedPath(to);
  useMatch({ path: resolvedPath.pathname, end: true })
  return (<li><Link to={to}>{text}</Link> </li>)
}