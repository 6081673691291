import React from 'react';
import { useEffect, useState } from 'react';
import Blob from './Blob.js'
import animatedBoxData from '../bodyData/animatedbox.js';


const AnimateBox = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const textChangeAreaH3Elements = document.querySelectorAll('.text-change-area-h3');
    const totalElements = textChangeAreaH3Elements.length;

    const ticker = setInterval(() => {
      const nextIndex = (currentIndex + 1) % totalElements;
      textChangeAreaH3Elements[currentIndex].classList.add('DisplayNone');
      textChangeAreaH3Elements[nextIndex].classList.remove('DisplayNone');
      setCurrentIndex(nextIndex);
    }, 300);

    return () => clearInterval(ticker);
  }, [currentIndex]);
  return (
    
      <section className="img-bg">
        <div className="next-banner-1">
          <div className="next-banner-2">
            <div className="next-banner-3">
              <div className="left-banner">
                <div className="bg-solid-color">
                  <div className="text-change-area">
                    <div className="text-change-area-h3 DisplayNone" data-index="0"><h3>RESPONSIVE</h3></div>
                    <div className="text-change-area-h3 DisplayNone" data-index="1"><h3>CREATIVE</h3></div>
                    <div className="text-change-area-h3 DisplayNone" data-index="2"><h3>Fast</h3></div>
                    <div className="text-change-area-h3 DisplayNone" data-index="3"><h3>MAGNUM</h3></div>
                    <div className="text-change-area-h3 DisplayNone" data-index="4"><h3>SPARK</h3></div>
                  </div>
                </div>
              </div>

              <Blob />

              <div className="right-banner">
                <div className="little-lower">
                  <div className="title-us">
                    <div className="line"></div>
                    <h3 className="who-we-are"> Who we are</h3>
                  </div>
                  <div className="description-who-we-are">
                    <h4>{animatedBoxData.text}
                    </h4>

                    <div className="hyper-link margin7perc">
                      <div className="SpecialLink IWantAMouseOVerNowBut75">
                        <a href="about" title="More About Us">
                          More About Us
                        </a>
                        <div className="Line"></div>
                      </div>
                    </div>
                  </div>

                  <div className="middle-lower-1">
                    <div className="middle-lower-2">
                      <div className="button-1">
                        {/* <!--                    Creativity--> */}

                      </div>
                    </div>
                    <div className="button-1"></div>
                  </div>
                  <div className=""></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
  )
}

export default AnimateBox
