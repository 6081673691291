
import React, { useState } from 'react';
import '../../css/ContactPage.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '0',
    message: ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const sendEmail = () => {
    setLoading(true);
    // console.log(formData)

    if (validateForm()) {
      
      fetch('https://node-email-server-master.vercel.app/sendmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data);
      setLoading(false);

      if (data.status === '1') {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      } else {
        setError(true);
        setTimeout(() => {
          setError(true);
        }, 5000);
        
      }
    })
    .catch(error => {
      console.error('Error:', error);
      setLoading(false);
      setError(true);
    });
  
     
    }
    else{
      setLoading(false);
      setError(true);

      return
    }
    //No one like their hardly filled information to be cleared out on simple mmistake
    // resetFormData();
    // console.log(formData)
    

   
  };
  // function resetFormData() {
  //   setFormData(
  //     {
  //       name: '',
  //       email: '',
  //       phone: '',
  //       subject: '0',
  //       message: ''
  //     }
  //   );
  // }
  const validateForm = () => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      formData.name.trim() === '' ||
      formData.email.trim() === '' ||
      formData.phone.trim() === '' ||
      formData.subject === '0' ||
      formData.message.trim() === ''
    ) {
      setError('All fields are required');
      return false;
    } 
    // else if (!emailRegex.test(formData.email.trim())) {
    //   setError('Invalid email address');
    //   return false;
    // } else if (formData.phone.trim().length !== 10 || !/^\d+$/.test(formData.phone.trim())) {
    //   setError('Invalid phone number');
    //   return false;
    // }

    setError('');
    getSubjectText(formData.subject)
    return true;
  };

  const getSubjectText = (subjectValue) => {
    switch (subjectValue) {
      case '1':
        return  formData.subject = 'Request a Call Back';
      case '2':
        return  formData.subject = 'Request a Quotation';
      case '3':
        return  formData.subject = 'Request a Meeting';
      case '4':
        return  formData.subject = 'General Enquiries';
      default:
        return  formData.subject = 'Subject';
    }
  };
  
  //test
  // const showResult=()=>{
  //   console.log(formData.name)
  //   console.log(formData.email)
  //   console.log(formData.phone)
  //   console.log(formData.subject)
  //   console.log(formData.message)
  //   getSubjectText(formData.subject)
  //   console.log(formData.subject)
  // }

  const showSuccessMessage = () => {
    return (
      <div id="sent-message" className="sent-message">
        Your message has been sent. Thank you!
      </div>
    );
  };

  const showErrorMessage = () => {
    return (
      <div id="error-message" className="error-message">
        Error
      </div>
    );
  };

  return (
    <>
      <section className="width100 contact-form1" style={{ marginBottom: "100px" }}>
        <form id="contact-form">
          <div className="form-row">
            <div className="input-group">
              <input type="text" id="name" onChange={handleChange} value={formData.name} placeholder="NAME" />
            </div>
            <div className="input-group">
              <input type="email" id="email" onChange={handleChange} value={formData.email} placeholder="EMAIL" />
            </div>
          </div>
          <div className="form-row">
            <div className="input-group">
              <input type="number" id="phone" onChange={handleChange} value={formData.phone} placeholder="PHONE NUMBER"/>
            </div>
            <div className="input-group">
              <select id="subject" className="subjectOption" onChange={handleChange} value={formData.subject} placeholder="SUBJECT">
                <option value="0" className="Option">Subject</option>
                <option value="1" className="Option">Request a Call Back</option>
                <option value="2" className="Option">Request a quotation</option>
                <option value="3" className="Option">Request A Meeting</option>
                <option value="4" className="Option">General Enquiries</option>
              </select>
            </div>
          </div>
          <div className="input-group">
            <textarea id="message" className="txtStyle" rows="8" placeholder="YOUR MESSAGE INCLUDING" onChange={handleChange} value={formData.message}></textarea>
          </div>
          <button id="submit" type="button" onClick={sendEmail} disabled={loading}>Submit</button>
          {/* {loading ? <div id="loading" className="loading">Loading</div> : 'Submit'} */}
         
          <div id="successDiv1">
            {loading && <div id="loading" className="loading">Loading</div>}
            {success && showSuccessMessage()}
            {error && showErrorMessage()}
          </div>
        </form>
      </section>
      {/* <button onClick={showResult}>show result</button> */}

    </>
  );
};

export default ContactForm;
