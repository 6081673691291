import React from 'react'
import {moreAboutUsData} from './bodyData/data.js'

const MoreAboutUs = () => {
    return (
        <div>
            <div className="more-about-us ">

                <div className="u-service">
                    <div className="services2">
                        <h3>{moreAboutUsData.title}</h3>
                    </div>
                </div>
                <div className="title-us">
                    <div className="line"></div>
                    <h3 className="who-we-are "> Who we are</h3>
                </div>
                <div className="u-service">
                    <div className="description-who-we-are max-width660">
                        <h4>{moreAboutUsData.description1}

                            <br /><br />
                            {moreAboutUsData.description2}
                            <br /><br />
                            {moreAboutUsData.description3}
                            
                        </h4>
                        <div className="hyper-link margin7perc">
                            <div className="SpecialLink IWantAMouseOVerNowBut75">
                                <a href="index.html" title="More About Us">
                                    More About Us
                                </a>
                                <div className="Line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoreAboutUs
