// const testimonials = [
//     {

//     }
// ]

const testimonialData = [
    {
      text:
        "I highly recommend Adx Factor for digital marketing, social media management, and branding. They have been instrumental in helping me grow my business from the ground up. With their help, my business has been able to reach new heights and my sales have been increasing daily thanks to their marketing and management plans. Adx Factor is a reliable and professional partner that I would recommend to anyone looking to take their business to the next level.",
      author: "John Doe",
      designation: "CEO at ClearTrim",
    },
    {
      text:
        "I highly recommend Adx Factor, a group of experts, to anyone who wants to make their business visible on the internet. With their help, I started this journey, and their excellent design work has been really impressive. As a teacher, I didn't know much about online business, but Adx Factor gave me the expert advice and constant support I needed. They are really good at digital marketing, managing social media, and branding. They not only met my expectations but exceeded them. Choosing Adx Factor has been a decision I'm very thankful for.",
      author: "Jane Smith",
      designation: "Managing Partner at OLabs",
    },
    {
      text:
        "I had the pleasure of working with Adx Factor for my digital marketing, social media management and branding needs. I was amazed by the results that I saw in a short period of time. My food sales increased 10 times due to their social media marketing strategies. They have a great team of experts who are knowledgeable and creative in their approach. They have been able to create unique designs that have been able to capture the attention of my target audience. I would highly recommend Adx Factor for any of your digital marketing, social media management, and branding needs.",
      author: "Jane Smith",
      designation: "Managing Partner at OLabs",
    },
    // Add more testimonials as needed
  ];
  
  export default testimonialData;
  