


import React, { Component } from 'react';
import testimonialData from '../../bodyData/testimonials.js';

class TestimonialChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTestimonialIndex: 0,
    };
  }

  componentDidMount() {
    this.displayTestimonials();
  }

  displayTestimonials = () => {
    setTimeout(() => {
      this.setState((prevState) => ({
        currentTestimonialIndex:
          (prevState.currentTestimonialIndex + 1) % testimonialData.length,
      }));
      this.displayTestimonials();
    }, 5000);
  };

  render() {
    const currentTestimonial = testimonialData[this.state.currentTestimonialIndex];

    return (


      <div className="testimonial-slider">
        <div className="testimonial">
          <p>{currentTestimonial.text}</p>
          <p className="author">{currentTestimonial.author}</p>
          <p className="designation">{currentTestimonial.designation}</p>
        </div>
      </div>


    );
  }
}

export default TestimonialChange;
