



const contactData = {
    title: "Contact Us",
    subTitle: "LET'S MAKE IT HAPPEN NOW",
    description: "If you want to address a challenge or achieve a goal by providing the finest digital experience for your customers and users, we're eager to connect with you. We're excited to welcome you to our offices!",
    directionsLink: "",
    directionsText: "Get Directions",
  };
  
  export default contactData;
  