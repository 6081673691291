
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Grid, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import logoData from '../../../bodyData/testimonial_logos';




const TestimonialSectionWithSwiper = () => {
  return (

    <Swiper
      slidesPerView={3}
      loop={true}
      autoplay={{
        delay: 1500,
        disableOnInteraction: true,
      }}

      grid={{
        rows: 3,
      }}

      spaceBetween={1}
      // pagination={{
      //   clickable: true,
      // }}

      modules={[Autoplay, Grid, Pagination]}
      className="testimonials-logos"
    >
      {logoData.map((logo, index) => (
        <SwiperSlide key={index} className="logoGrid">
          <img className='blackAndWhiteImg' src={logo.src} alt={logo.alt} title={logo.title} />
        </SwiperSlide>
      ))}
    </Swiper>

  );
}

export default TestimonialSectionWithSwiper;



// // import React from 'react';
// // import logoData from '../../../getData/testimonial_logos'

// // const TestimonialSection = () => {
// //   return (
// //     <section className="grid">
// //       {logoData.map((logo, index) => (
// //         <div key={index} className="logo">
// //           <img src={logo.src} alt={logo.alt} title={logo.title} />
// //         </div>
// //       ))}
// //     </section>
// //   );
// // }

// // export default TestimonialSection;

// // import React, { useRef, useState } from 'react';

// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/grid';
// import 'swiper/css/pagination';
// import { Grid, Pagination } from 'swiper/modules';

// export default function App() {
//   return (
//     <>
//       <Swiper
//         slidesPerView={3}
//         autoplay={true}
//         grid={{
//           rows: 3,
//         }}
//         spaceBetween={20}
//         pagination={{
//           clickable: true,
//         }}
//         modules={[Grid, Pagination]}
//         className="mySwiper"
//       >
//         <SwiperSlide>Slide 1</SwiperSlide>
//         <SwiperSlide>Slide 2</SwiperSlide>
//         <SwiperSlide>Slide 3</SwiperSlide>
//         <SwiperSlide>Slide 4</SwiperSlide>
//         <SwiperSlide>Slide 5</SwiperSlide>
//         <SwiperSlide>Slide 6</SwiperSlide>
//         <SwiperSlide>Slide 7</SwiperSlide>
//         <SwiperSlide>Slide 8</SwiperSlide>
//         <SwiperSlide>Slide 9</SwiperSlide>
//         <SwiperSlide>Slide 10</SwiperSlide>
//         <SwiperSlide>Slide 11</SwiperSlide>
//         <SwiperSlide>Slide 12</SwiperSlide>
//       </Swiper>
//     </>
//   );
// }

