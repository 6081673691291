import React from 'react'

const WhatWeDo = () => {
  return (
    <div>
      <section className="img-bg">
  <div className="tbl">
    <div className="tbl-col">

      <div className="left50">
        <div className="services">
          <h2>SERVICES MADE BY EXPERTS</h2>
        </div>

        <div className="title-us extra">
          <div className="line"></div>
          <h3 className="who-we-are"> What we do</h3>
        </div>
        <div className="description-who-we-are extra margin7perc">
          <p>
            At Adx Factor, we specialize in digital marketing and advertising across a wide spectrum of social media platforms. We also excel in branding and design, steering your brand toward new horizons.
            <br/><br/>

            We're dedicated to delivering an engaging and interactive communication experience across various channels, all with the ultimate goal of ensuring a substantial return on investment for our valued clients.

          </p>
        </div>

        <div className="hyper-link">
          <div className="SpecialLink IWantAMouseOVerNowBut75">
            <a href="index.html" title="View Our Services">
              View Our Services
            </a>
            <div className="Line"></div>
          </div>
        </div>


      </div>


      <div className="right50">

        
      </div>



    </div>
  </div>
</section>
    </div>
  )
}

export default WhatWeDo
