import React, { useState, useRef } from 'react';

const WhoWeAre = () => {
  const [isPlaying, setPlaying] = useState(true);
//   const [volume, setVolume] = useState(1);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      
      if (video.paused) {
        video.volume=0.05;
        video.muted=false;
        video.play();
      } else {
        video.pause();
        
      }
      setPlaying(!isPlaying);
    }
  };

//   const handleVolumeChange = (value) => {
//     const video = videoRef.current;
//     if (video) {
//       video.volume = value;
//       setVolume(value);
//     }
//   };

  return (
    <div className="video-container">
      <div className="adVideo">
        <video
          ref={videoRef}
          className="adVideoTag"
          autoPlay={true}
          muted={true} 
          loop={false}
          onClick={togglePlayPause}
          
        >
          <source src="/videos/adxAd.mp4"   type="video/mp4" />
          
          {/* <source src='/public/videos/adsAdWeb.webp' type='video/webp'/> */}
        </video>
        {/* <div className={`controls ${isPlaying ? 'playing' : 'paused'}`}>
          <button onClick={togglePlayPause}>{isPlaying ? 'Pause' : 'Play'}</button>
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={volume}
            onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
          />
        </div> */}
      </div>
    </div>
  );
};

export default WhoWeAre;


// import React, { useState, useRef } from 'react';

// const WhoWeAre = () => {
//   const [isPlaying, setPlaying] = useState(true);
//   const [volume, setVolume] = useState(1);
//   const videoRef = useRef(null);

//   const togglePlayPause = () => {
//     const video = videoRef.current;
//     if (video) {
//       if (video.paused) {
//         video.play();
//       } else {
//         video.pause();
//       }
//       setPlaying(!isPlaying);
//     }
//   };

//   const handleVolumeChange = (value) => {
//     const video = videoRef.current;
//     if (video) {
//       video.volume = value;
//       setVolume(value);
//     }
//   };

//   return (
//     <div>
//       <div className="size-banner alignTextCentre">
//         <div className="adVideo">
//           <video
//             ref={videoRef}
//             className="adVideoTag"
//             autoPlay={true}
//             muted={false} 
//             loop
//             onClick={togglePlayPause}
//           >
//             <source src="/videos/adxAd.mp4" type="video/mp4" />
            
//             {/* <source src='/public/videos/adsAdWeb.webp' type='video/webp'/> */}
//           </video>
//         </div>

//         <div className="controls">
//           {/* <button onClick={togglePlayPause}>{isPlaying ? 'Pause' : 'Play'}</button> */}
//           <input
//             type="range"
//             min="0"
//             max="1"
//             step="0.1"
//             value={volume}
//             onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WhoWeAre;

// // import React from 'react'

// // const WhoWeAre = () => {
// //     return (
// //         <div>
// //             <div className="size-banner  alignTextCentre">
// //                 {/* <div className="image-banner-np"> */}
// //                 <div className='adVideo'>
// //                 {/* <h2>Who-<br /> We Are</h2> */}
// //                     <video className='adVideoTag'autoPlay={true} muted    loop>
// //                         <source src='/videos/adxAd.mp4' type='video/mp4'/>
// //                         {/* <source src='/public/videos/adsAdWeb.webp' type='video/webp'/> */}
// //                     </video>
                    
// //                 </div>


// //             </div>
// //         </div>
// //     )
// // }

// // export default WhoWeAre
// // // paddingAll