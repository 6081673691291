import React from 'react'
import ServicesComponent from '../pagesContents/homePage/components/ServicesComponent';; //component
const Services = () => {
  return (
    <div>
      <ServicesComponent/>
    </div>
  )
}

export default Services
