import React from 'react'
import servicesComponentData from '../bodyData/servicesComponent'

const ServicesComponent = () => {
  return (
    
      <section className="img-bg">
  <div className="tbl">
    <div className="tbl-col">

    <div className="left50">
      <div className="services">
        <h2>SERVICES MADE BY EXPERTS</h2>
      </div>

            <div className="title-us extra">
                <div className="line"></div>
                <h3 className="who-we-are"> What we do</h3>
              </div>
            <div className="description-who-we-are extra margin7perc">
              <p>
                {servicesComponentData.text1}
                <br/>
                <br/>
                {servicesComponentData.text2}
              </p>
            </div>

            <div className="hyper-link">
              <div className="SpecialLink IWantAMouseOVerNowBut75">
                <a href="services" title="View Our Services">
                  View Our Services
                </a>
                <div className="Line"></div>
              </div>
            </div>


    </div>


    <div className="right50">

            {/* <h2>hello</h2> */}
    </div>

    </div>

    
  </div>
</section>
    
  )
}

export default ServicesComponent
