import React from 'react'

const RecentWork = () => {
  return (
    <div>
      <section>
<div className="width100 heightAuto">
  <div className="heightAuto">
  <div className="row">
    <div className="width100 recent-work-h2">
      <h2>
        Recent Work
      </h2>
    </div>
  </div>
  <div className="width100">

    <div className="display-cel">
      <div className="row borderLine ">
        <div className="recent-work">

          <div className="image-recent-work-container">
            <img className="image-recent-work" src="img/images2/backImage.jpeg" alt=""/>
          </div>



          <div className="recent-work-info">
            <div className="title-us">
              <div className="line"></div>
              <h3 className="who-we-are ">Client</h3>
              <h6>Barakat Pro Fashion


                <div className="hyper-link ">
                  <div className="SpecialLink IWantAMouseOVerNowBut75">
                    <a href="index.html" title="Read More">
                      Read More
                    </a>
                    <div className="Line"></div>
                  </div>
                </div>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div className="display-cel margin0">
      <div className="row borderLine margin0">
        <div className="recent-work margin0">

          <div className="image-recent-work-container">
                <img className="image-recent-work" src="img/images2/backImage.jpeg" alt=""/>
          </div>



          <div className="recent-work-info">
            <div className="title-us">
              <div className="line"></div>
              <h3 className="who-we-are ">Client</h3>
              <h6>Barakat Pro Fashion


                <div className="hyper-link ">
                  <div className="SpecialLink IWantAMouseOVerNowBut75">
                    <a href="index.html" title="Read More">
                      Read More
                    </a>
                    <div className="Line"></div>
                  </div>
                </div>
              </h6>

            </div>
          </div>
        </div>

      </div>
    </div>




    <div className="display-cel">
      <div className="row borderLine">
        <div className="recent-work">

            <div className="image-recent-work-container">
                  <img className="image-recent-work" src="img/images2/backImage.jpeg" alt=""/>
            </div>

          <div className="recent-work-info">
            <div className="title-us">
              <div className="line"></div>
              <h3 className="who-we-are ">Client</h3>
              <h6>Barakat Pro Fashion
                <div className="hyper-link ">
                  <div className="SpecialLink IWantAMouseOVerNowBut75">
                    <a href="index.html" title="Read More">
                      Read More
                    </a>
                    <div className="Line"></div>
                  </div>
                </div>
              </h6>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


  </div>
</div>

</section>
    </div>
  )
}

export default RecentWork