import React from 'react'
import contactData from './bodyData/data'

const LetsConnect = () => {
  return (
    <>

<div className="contact-us-holder ">
    <div className="contact-us-50-holder">
  <div className="u-contact-form">
  <div className="title-us">
    <div className="line-form"></div>
    <h3 className="who-we-are ">  {contactData.title?contactData.title : 'Contact Us'}</h3>
  </div>
  </div>

  <div className="u-contact-form">
    <div className="u-contact-h3-from">
      <h3>{ contactData.subTitle?contactData.subTitle : "LET'S MAKE IT HAPPEN NOW"}</h3>
    </div>
  </div>

  <div className="u-contact-form">
    <div className="description-who-we-are-from max-width660">
      <h4>
        {contactData.description ? contactData.description:  "If you want to address a challenge or achieve a goal by providing the finest digital experience for your customers and users, we're eager to connect with you. We're excited to welcome you to our offices!"}
      </h4>
    </div>
    <div className="hyper-link margin7perc">
      <div className="SpecialLink IWantAMouseOVerNowBut75">
        <a href={contactData.directionsLink? contactData.directionsLink:"/"} title="Get Directions">
          {contactData.directionsText? contactData.directionsText: "Get Directions"}
        </a>
        <div className="Line"></div>
      </div>
    </div>
  </div>


    </div>
  </div>
      
    </>
  )
}

export default LetsConnect
