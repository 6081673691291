import React from 'react'
import LetsConnect from '../pagesContents/contactPage/LetsConnect'
import ContactForm from '../pagesContents/contactPage/ContactForm'

const Contact = () => {
  return (
    <div>
        <section className="contact-form-holder">
            <LetsConnect/>
            <ContactForm/>
        </section>
      
    </div>
  )
}

export default Contact
