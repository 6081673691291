import React from 'react'

const TextTemplate = () => {
  return (


    <section style={{ paddingTop: '50px' }} className="img-bg ">
      <div className="back-banner-1">
        <div className="back-banner-2">
          <div className="image-banner cover-img" >
            {/* style="background-size: cover" */}
            <h3 className="front-h3">Ad X.</h3>

          </div>

          <div className="data-banner">

            <div className="heading-banner">
              <h2>CREATIVITY MEETS TECHNOLOGY</h2>
            </div>
            <div className="paragraph-banner">
              <h4>Social Media Management - Paid Marketing - Branding - Design - PR Management
              </h4>
            </div>

          </div>

          <div>
            <div className="social-icon-banner">
              <a className="social-icon-banner-i" target='_blank' rel="noreferrer" href="https://web.facebook.com/profile.php?id=61550773778679">
                <i className="fa-brands fa-facebook-f"></i> </a>

              {/* <a className="social-icon-banner-i" href="/">
                <i className="fa-brands fa-tiktok"></i> </a>

              <a className="social-icon-banner-i" href="/">
                <i className="fa-brands fa-twitter"></i> </a> */}

              <a className="social-icon-banner-i" target='_blank' rel="noreferrer" href="https://www.instagram.com/adxfactor/">
                <i className="fa-brands fa-instagram"></i> </a>

              {/* <a className="social-icon-banner-i" href="/">
                <i className="fa-brands fa-pinterest-p"></i> </a> */}

              <a className="social-icon-banner-i" href='https://www.linkedin.com/company/adx-factor/' target='_blank' rel="noreferrer">
                <i className="fa-brands fa-linkedin-in"></i> </a>

              {/* <a className="social-icon-banner-i" href="/">
                <i className="fa-brands fa-whatsapp"></i> </a> */}
            </div>
          </div>


        </div>
      </div>

    </section>

  )
}

export default TextTemplate
