import { Route, Routes } from 'react-router-dom';
import './css/ContactPage.css';
import './css/App.css';
import './css/About.css';
import './css/theme/darktheme.css'


import Nav from './mainComponents/Nav.js'
import Footer from './mainComponents/Footer.js';
import Home from './pages/Home.js'
import About from './pages/About.js';
import Contact from './pages/Contact.js';
import Services from './pages/Services.js';


import { useEffect, useState } from 'react';

import PageNotFound from './pagesContents/PageNotFound.js';



function App() {

  const [isDarkMode, setIsDarkMode] = useState(false);


  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setIsDarkMode(prefersDarkMode);

    const handleThemeChange = (event) => {
      setIsDarkMode(event.matches);
    };

    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    darkModeMediaQuery.addEventListener('change', handleThemeChange);


    return () => {
      darkModeMediaQuery.removeEventListener('change', handleThemeChange);
    };
  }, []);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);
  return (


    <>
      <Nav isItDarkMode={isDarkMode} />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} /> {/*this is not home page that is defined in pages. this is a component from home page */}
        {/* <Route path='/coreteam' element={<CoreTeam />} /> //Not needed by client anymore */}
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <Footer />
    </>


  );
}

export default App;




// import { SpeedInsights } from "@vercel/speed-insights/react"
// import { Analytics } from '@vercel/analytics/react';
//     <SpeedInsights />
//     <Analytics />